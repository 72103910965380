/* External Imports */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Pincites logo fill in light and dark mode */
path#pincites_logo {
  fill: url(#gradient_blue);
}
@media (prefers-color-scheme: dark) {
  path#pincites_logo {
    fill: url(#gradient_white);
  }
}
